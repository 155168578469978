import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Layout from "../../../../components/layouts/main"
import SEO from "../../../../components/seo"
import {PatreonButton, PatreonCorner} from "../../../../components/patreon"
import BackButton from "../../../../components/backbutton"
import * as styles from "../../../../components/styles/japanese.module.scss"
import genki from "../../../../components/japanese/genki-lessons"
import Badge from "../../../../components/badge"
import Helmet from "react-helmet"

const GenkiLesson = (props) => (
  <div className={styles.lesson}>
    <h3>{props.heading} {genki["comingsoon"].includes(props.heading) && (<Badge>Coming Soon</Badge>)}</h3>
    <ul>
      {props.lessons.map((lesson) => {
        if(lesson.page === "") {
          if(lesson.early) {
            return (
              <li><a href={lesson.early} dangerouslySetInnerHTML={{ __html: lesson.title }} /> <Badge color="var(--patreon)">Early Access</Badge></li>
            )
          }
          else if(lesson.patreon) {
            return (
              <li><a href={lesson.patreon} dangerouslySetInnerHTML={{ __html: lesson.title }} /> <Badge color="var(--patreon)">Patreon</Badge></li>
            )
          }
          return (
            <li key={lesson.title}><span dangerouslySetInnerHTML={{ __html: lesson.title }} /> {!genki["comingsoon"].includes(props.heading) && (<Badge>WIP</Badge>)}</li>
          )
        }
        return(
            <li key={lesson.title}>
              <Link to={"/projects/japanese/" + lesson.page} dangerouslySetInnerHTML={{ __html: lesson.title }} />
              {lesson.beta && (<Badge>Beta</Badge>)}
              {lesson.badge && (<Badge>{lesson.badge}</Badge>)}
            </li>
        )
      })}
    </ul>
  </div>
)

const GenkiBook = (props) => (
    <>
      <h5>{props.book}</h5>
      {Object.keys(genki[props.book]).map((lesson, i) => {
        if(i % 2 === 0 && Object.keys(genki[props.book])[i+1]) {
          return(
              <div style={{display:"table-row"}}>
                <GenkiLesson
                  key={lesson}
                  heading={lesson}
                  lessons={genki[props.book][lesson]}
                />
                <GenkiLesson
                  key={Object.keys(genki[props.book])[i+1]}
                  heading={Object.keys(genki[props.book])[i+1]}
                  lessons={genki[props.book][Object.keys(genki[props.book])[i+1]]}
                />
              </div>
          )
        } else if (i % 2 === 0) {
          return(
            <div style={{display:"table-row"}}>
              <GenkiLesson
                key={lesson}
                heading={lesson}
                lessons={genki[props.book][lesson]}
              />
            </div>
          )
        }
        return(null)
      })}
    </>
)

const GenkiPage = () => (
  <Layout
    heading={(<div className={styles.banner2}>
      <h1 className={styles.heading} style={{marginBottom: 0}}>Supplementary Genki Practice</h1>
    </div>)}>
    <SEO title="Genki" description="Collection of Japanese sentence and grammar exercises to supplement each of the lessons in the Genki Textbooks." />
    <p style={{fontSize:"14px"}}>Welcome to the Genki supplementary content page! This page is a collection of exercises to supplement each of the lessons in the <a href="https://amzn.to/33fgwAM">Genki Textbook</a>, providing convenience and feedback that you can&#39;t get with the workbook alone! Lessons are currently being added and updated constantly, so keep checking back and <a href="https://www.patreon.com/stevenkraft">follow me</a> on Patreon to get the latest information! <i>Note: The following lessons and vocab are based on the 2nd Edition of the textbook. Expect minor differences if using a more recent edition.</i></p>
    <GenkiBook book="Genki I" />
    <br></br>
    <GenkiBook book="Genki II" />
    <br></br>
    <GenkiBook book="Genki I + II" />

    <p style={{textAlign:"center"}}>Thank you to all of my <Link style={{fontWeight: 400}} to="/projects/japanese/patreon">Patreon Supporters</Link> for helping me keep this website running!</p>
    <p style={{margin:"auto", display:"table"}} ><PatreonButton /></p>
    <PatreonCorner />
    <BackButton color="white" />
  </Layout>
)

GenkiLesson.propTypes = {
  heading: PropTypes.string.isRequired,
  lessons: PropTypes.array.isRequired
}

GenkiBook.propTypes = {
  book: PropTypes.string.isRequired
}

export default GenkiPage
