const genki = {
  "Genki I" : {
    "Lesson 1": [
      {title:"Lesson 1-1 - XはYです", page:"genki/genki1-1/"},
      {title:"Lesson 1-2 - Question Sentences", page:"genki/genki1-2/"},
      {title:"Lesson 1-3 - Noun<sub>1</sub> の Noun<sub>2</sub>", page:"genki/genki1-3/"},
      {title:"Numbers Practice", page:"numbers/"},
      {title:"Time Practice", page:"time/"},
      {title:"Vocab", page:"genki/vocab1/"},
      {title:"Sentence Review", page:"", patreon:"https://www.patreon.com/posts/39913794"},
    ],
    "Lesson 2": [
      {title:"Lesson 2-1 - これ それ あれ どれ", page:"genki/genki2-1/"},
      {title:"Lesson 2-2 - この/その/あの/どの + Noun", page:"genki/genki2-2/"},
      {title:"Lesson 2-3 - ここ そこ あそこ どこ", page:"genki/genki2-3/"},
      {title:"Lesson 2-4 - だれの Noun", page:"genki/genki2-4/"},
      {title:"Lesson 2-5 - Noun も", page:"genki/genki2-5/"},
      {title:"Lesson 2-6 - Noun じゃないです", page:"genki/genki2-6/"},
      {title:"Lesson 2-7 - ～ね/～よ", page:"genki/genki2-7/"},
      {title:"Vocab", page:"genki/vocab2/"},
      {title:"Sentence Review", page:"", patreon:"https://www.patreon.com/posts/39913794"},
    ],
    "Lesson 3": [
      {title:"Lesson 3-1 - Verb Conjugation", page:"politeform/"},
      {title:"Lesson 3-2 - Verb Types and the \"Present Tense\"", page:"genki/genki3-2/"},
      {title:"Lesson 3-3 - Particles", page:"genki/genki3-3/"},
      {title:"Lesson 3-4 - Time Reference", page:"genki/genki3-4/"},
      {title:"Lesson 3-5 - ～ませんか", page:"genki/genki3-5/"},
      {title:"Lesson 3-7 - Frequency Adverbs", page:"genki/genki3-7/"},
      {title:"Kanji", page:"genki/kanji3/"},
      {title:"Vocab", page:"genki/vocab3/"},
      {title:"Sentence Review", page:"", patreon:"https://www.patreon.com/posts/39913794"},
    ],
    "Lesson 4": [
      {title:"Lesson 4-1 - Xがあります/います", page:"genki/genki4-1/"},
      {title:"Lesson 4-2 - Describing Where Things Are", page:"genki/genki4-2/"},
      {title:"Lesson 4-3 - Past Tense of です", page:"genki/genki4-3/"},
      {title:"Lesson 4-4 - Past Tense of Verbs", page:"pastform/?polite=true"},
      {title:"Lesson 4-5 - も", page:"genki/genki4-5/"},
      {title:"Lesson 4-6 - <ruby>一<rt>いち</rt></ruby><ruby>時<rt>じ</rt></ruby><ruby>間<rt>かん</rt></ruby>", page:"genki/genki4-6/"},
      {title:"Lesson 4-7 - たくさん", page:"genki/genki4-7/"},
      {title:"Lesson 4-8 - と", page:"genki/genki4-8/"},
      {title:"Kanji", page:"genki/kanji4/"},
      {title:"Vocab", page:"genki/vocab4/"},
      {title:"Sentence Review", page:"", patreon:"https://www.patreon.com/posts/39913794"},
    ],
    "Lesson 5": [
      {title:"Lesson 5-1 - Adjectives", page:"genki/genki5-1/", beta:true},
      {title:"Lesson 5-2 - <ruby>好<rt>す</rt>き</ruby>/きらい(な)", page:"genki/genki5-2/", beta:true},
      {title:"Lesson 5-3 - ～ましょう/～ましょうか", page:"genki/genki5-3/", beta:true},
      {title:"Lesson 5-4 - Counting", page:"counters/", beta:true},
      {title:"Kanji", page:"genki/kanji5/"},
      {title:"Vocab", page:"genki/vocab5/"},
      {title:"Sentence Review", page:"", patreon:"https://www.patreon.com/posts/39913794"},
    ],
    "Lesson 6": [
      {title:"Lesson 6-1 - Te-form", page:"teform/"},
      {title:"Lesson 6-2 - ～てください", page:"genki/genki6-2/", beta:true},
      {title:"Lesson 6-3 - ～てもいいです", page:"genki/genki6-3/", beta:true},
      {title:"Lesson 6-4 - ～てはいけません", page:"genki/genki6-4/", beta:true},
      {title:"Lesson 6-5 - Describing Two Activities", page:"genki/genki6-5/", beta:true},
      {title:"Lesson 6-6 - ～から", page:"genki/genki6-6/", beta:true},
      {title:"Lesson 6-7 - ～ましょうか", page:"genki/genki6-7/", beta:true},
      {title:"Kanji", page:"genki/kanji6/"},
      {title:"Vocab", page:"genki/vocab6/"},
      {title:"Sentence Review", page:"", patreon:"https://www.patreon.com/posts/39913794"},
    ],
    "Lesson 7": [
      {title:"Lesson 7-1 - ～ている", page:"genki/genki7-1/", beta:true},
      {title:"Lesson 7-2 - メアリーさんは<ruby>髪<rt>かみ</rt></ruby>が<ruby>長<rt>なが</rt></ruby>いです", page:"genki/genki7-2/", beta:true},
      {title:"Lesson 7-3 - Te-forms for Joining Sentences", page:"genki/genki7-3/", beta:true},
      {title:"Lesson 7-4 - Verb Stem + に<ruby>行<rt>い</rt></ruby>く", page:"genki/genki7-4/", beta:true},
      {title:"Lesson 7-5 - Counting People", page:"counters/?people=true"},
      {title:"Kanji", page:"genki/kanji7/"},
      {title:"Vocab", page:"genki/vocab7/"},
      {title:"Sentence Review", page:"", patreon:"https://www.patreon.com/posts/39913794"},
    ],
    "Lesson 8": [
      {title:"Lesson 8-1 - Short Forms", page:"politeform/?reverse=true&rand=true"},
      {title:"Lesson 8-3 - ～と<ruby>思<rt>おも</rt></ruby>います/～と<ruby>言<rt>い</rt></ruby>ってました", page:"genki/genki8-3/", beta:true},
      {title:"Lesson 8-4 - ～ないでください", page:"genki/genki8-4/", beta:true},
      {title:"Lesson 8-5 - Verb のが<ruby>好<rt>す</rt></ruby>きです", page:"genki/genki8-5/", beta:true},
      {title:"Lesson 8-6 - が", page:"genki/genki8-6/", beta:true},
      {title:"Lesson 8-7 - <ruby>何<rt>なに</rt></ruby>か and <ruby>何<rt>なに</rt></ruby>も", page:"genki/genki8-7/"},
      {title:"Kanji", page:"genki/kanji8/"},
      {title:"Vocab", page:"genki/vocab8/"},
      {title:"Sentence Review", page:"", patreon:"https://www.patreon.com/posts/39913794"},
    ],
    "Lesson 9": [
      {title:"Lesson 9-1 - Past Tense Short Forms (Verbs)", page:"pastform"},
      {title:"Lesson 9-1 - Past Tense Short Forms (Adjectives)", page:"adj-pastform"},
      {title:"Lesson 9-2 - Qualifying Nouns with Verb and Adjectives", page:"genki/genki9-2/", beta:true},
      {title:"Lesson 9-3 - まだ～ていません", page:"genki/genki9-3/", beta:true},
      {title:"Lesson 9-4 - ～から", page:"genki/genki9-4/", beta:true},
      {title:"Kanji", page:"genki/kanji9/"},
      {title:"Vocab", page:"genki/vocab9/"},
      {title:"Sentence Review", page:"", patreon:"https://www.patreon.com/posts/39913794"},
    ],
    "Lesson 10": [
      {title:"Lesson 10-1 - Comparison Between Two Items", page:"genki/genki10-1/", beta:true},
      {title:"Lesson 10-2 - Comparison Among Three or More Items", page:"genki/genki10-2/", beta:true},
      {title:"Lesson 10-3 - Adjectives/Noun + の", page:"genki/genki10-3/", beta:true},
      {title:"Lesson 10-4 - ～つもりだ", page:"genki/genki10-4/", beta:true},
      {title:"Lesson 10-5 - Adjective + なる", page:"genki/genki10-5/", beta:true},
      {title:"Lesson 10-6 - どこかに/どこにも", page:"genki/genki10-6/", beta:true},
      {title:"Lesson 10-7 - で", page:"genki/genki10-7/", beta:true},
      {title:"Kanji", page:"genki/kanji10/"},
      {title:"Vocab", page:"genki/vocab10/"},
      {title:"Sentence Review", page:"", patreon:"https://www.patreon.com/posts/39913794"},
    ],
    "Lesson 11": [
      {title:"Lesson 11-1 - ～たい", page:"genki/genki11-1/", beta:true},
      {title:"Lesson 11-2 - ～たり～たりする", page:"genki/genki11-2/", beta:true},
      {title:"Lesson 11-3 - ～ことがある", page:"genki/genki11-3/", beta:true},
      {title:"Lesson 11-4 - Noun<sub>A</sub> や Noun<sub>B</sub>", page:"genki/genki11-4/", beta:true},
      {title:"Kanji", page:"genki/kanji11/"},
      {title:"Vocab", page:"genki/vocab11/"},
      {title:"Sentence Review", page:"", patreon:"https://www.patreon.com/posts/39913794"},
    ],
    "Lesson 12": [
      {title:"Lesson 12-1 - ～んです", page:"genki/genki12-1/", beta:true},
      {title:"Lesson 12-2 - ～すぎる", page:"genki/genki12-2/", beta:true},
      {title:"Lesson 12-3 - ～ほうがいいです", page:"genki/genki12-3/", beta:true},
      {title:"Lesson 12-4 - ～ので", page:"genki/genki12-4/", beta:true},
      {title:"Lesson 12-5 - ～なければいけません/～なきゃいけません", page:"genki/genki12-5/", beta:true},
      {title:"Lesson 12-6 - ～でしょう", page:"genki/genki12-6/", beta:true},
      {title:"Kanji", page:"genki/kanji12/"},
      {title:"Vocab", page:"genki/vocab12/"},
      {title:"Sentence Review", page:"", patreon:"https://www.patreon.com/posts/39913794"},
    ],
    "Review": [
      {title:"Genki I - Complete Sentence Review", page:"", patreon:"https://www.patreon.com/posts/47029146/"},
      {title:"Genki I - Complete Vocab Review",    page:"", patreon:"https://www.patreon.com/posts/79196752/"}
  ]
  },
  "Genki II" : {
    "Lesson 13": [
      {title:"Lesson 13-1 - Potential Verbs", page:"potentialform/", beta:true},
      {title:"Lesson 13-2 - ～し", page:"", page:"genki/genki13-2/"},
      {title:"Lesson 13-3 - ～そうです (It looks like...)", page:"genki/genki13-3/", beta:true},
      {title:"Lesson 13-4 - ～てみる", page:"genki/genki13-4/", beta:true},
      {title:"Lesson 13-5 - なら", page:"genki/genki13-5/", beta:true},
      {title:"Lesson 13-6 - <ruby>一<rt>いっ</rt></ruby><ruby>週<rt>しゅう</rt></ruby><ruby>間<rt>かん</rt></ruby>に<ruby>三<rt>さん</rt></ruby><ruby>回<rt>かい</rt></ruby>", page:"genki/genki13-6/", beta:true},
      {title:"Kanji", page:"genki/kanji13/"},
      {title:"Vocab", page:"genki/vocab13/"},
      {title:"Sentence Review", page:"", patreon:"https://www.patreon.com/posts/39913794"},
    ],
    "Lesson 14": [
      {title:"Lesson 14-1 - ほしい", page:"genki/genki14-1/", beta:true},
      {title:"Lesson 14-2 - ～かもしれません", page:"genki/genki14-2/", beta:true},
      {title:"Lesson 14-3 - あげる/くれる/もらう", page:"genki/genki14-3/", beta:true},
      {title:"Lesson 14-4 - ～たらどうですか", page:"genki/genki14-4/", beta:true},
      {title:"Lesson 14-5 - Number + も/Number + しか + Negative", page:"genki/genki14-5/", beta:true},
      {title:"Kanji", page:"genki/kanji14/"},
      {title:"Vocab", page:"genki/vocab14/"},
      {title:"Sentence Review", page:"", patreon:"https://www.patreon.com/posts/39913794"},
    ],
    "Lesson 15": [
      {title:"Lesson 15-1 - Volitional Form", page:"volitionalform/"},
      {title:"Lesson 15-2 - Volitional Form + と<ruby>思<rt>おも</rt></ruby>っています", page:"genki/genki15-2/", beta:true},
      {title:"Lesson 15-3 - ～ておく", page:"genki/genki15-3/", beta:true},
      {title:"Lesson 15-4 - Using Sentences to Qualify Nouns", page:"genki/genki15-4/", beta:true},
      {title:"Kanji", page:"genki/kanji15/"},
      {title:"Vocab", page:"genki/vocab15/"},
      {title:"Sentence Review", page:"", patreon:"https://www.patreon.com/posts/39913794"},
    ],
    "Lesson 16": [
      {title:"Lesson 16-1 - ～てあげる/くれる/もらう", page:"genki/genki16-1/", beta:true},
      {title:"Lesson 16-2 - ～ていただけませんか", page:"genki/genki16-2/", beta:true},
      {title:"Lesson 16-3 - ～といい", page:"genki/genki16-3/", beta:true},
      {title:"Lesson 16-4 - ～<ruby>時<rt>とき</rt></ruby>", page:"genki/genki16-4/", beta:true},
      {title:"Lesson 16-5 - ～てすみませんでした", page:"genki/genki16-5/", beta:true},
      {title:"Kanji", page:"genki/kanji16/"},
      {title:"Vocab", page:"genki/vocab16/"},
      {title:"Sentence Review", page:"", patreon:"https://www.patreon.com/posts/39913794"},
    ],
    "Lesson 17": [
      {title:"Lesson 17-1 - ～そうです (I hear)", page:"genki/genki17-1/", beta:true},
      {title:"Lesson 17-2 - ～って", page:"genki/genki17-2/", beta:true},
      {title:"Lesson 17-3 - ～たら", page:"genki/genki17-3/", beta:true},
      {title:"Lesson 17-4 - ～なくてもいいです", page:"genki/genki17-4/", beta:true},
      {title:"Lesson 17-5 - ～みたいです", page:"genki/genki17-5/", beta:true},
      {title:"Lesson 17-6 - ～<ruby>前<rt>まえ</rt></ruby>に/～てから", page:"genki/genki17-6/", beta:true},
      {title:"Kanji", page:"genki/kanji17/"},
      {title:"Vocab", page:"genki/vocab17/"},
      {title:"Sentence Review", page:"", patreon:"https://www.patreon.com/posts/39913794"},
    ],
    "Lesson 18": [
      {title:"Lesson 18-1 - Transitivity Pairs", page:"transitive/"},
      {title:"Lesson 18-2 - ～てしまう", page:"genki/genki18-2/", beta:true},
      {title:"Lesson 18-3 - ～と", page:"genki/genki18-3/", beta:true},
      {title:"Lesson 18-4 - ～ながら", page:"genki/genki18-4/", beta:true},
      {title:"Lesson 18-5 - ～ばよかったです", page:"genki/genki18-5/", beta:true},
      {title:"Kanji", page:"genki/kanji18/"},
      {title:"Vocab", page:"genki/vocab18/"},
      {title:"Sentence Review", page:"", patreon:"https://www.patreon.com/posts/39913794"},
    ],
    "Lesson 19": [
      {title:"Lesson 19-1 - Honorific Verbs", page:"genki/genki19-1/", beta:true},
      {title:"Lesson 19-2 - Giving Respectful Advice", page:"genki/genki19-2/", beta:true},
      {title:"Lesson 19-3 - ～てくれてありがとう", page:"genki/genki19-3/", beta:true},
      {title:"Lesson 19-4 - ～てよかったです", page:"genki/genki19-4/", beta:true},
      {title:"Lesson 19-5 - ～はずです", page:"genki/genki19-5/", beta:true},
      {title:"Kanji", page:"genki/kanji19/"},
      {title:"Vocab", page:"genki/vocab19/"},
      {title:"Sentence Review", page:"", patreon:"https://www.patreon.com/posts/39913794"},
    ],
    "Lesson 20": [
      {title:"Lesson 20-1 - Extra-modest Expressions", page:"genki/genki20-1/", beta:true},
      {title:"Lesson 20-2 - Humble Expressions", page:"genki/genki20-2/", beta:true},
      {title:"Lesson 20-3 - ～ないで", page:"genki/genki20-3/", beta:true},
      {title:"Lesson 20-4 - Questions Within Larger Sentences", page:"genki/genki20-4/", beta:true},
      {title:"Lesson 20-5 - Name という Item", page:"genki/genki20-5/", beta:true},
      {title:"Lesson 20-6 - ～やすい/～にくい", page:"genki/genki20-6/", beta:true},
      {title:"Kanji", page:"genki/kanji20/"},
      {title:"Vocab", page:"genki/vocab20/"},
      {title:"Sentence Review", page:"", patreon:"https://www.patreon.com/posts/39913794"},
    ],
    "Lesson 21": [
      {title:"Lesson 21-1 - Passive Sentences", page:"genki/genki21-1/", beta:true},
      {title:"Lesson 21-2 - ～てある", page:"genki/genki21-2/", beta:true},
      {title:"Lesson 21-3 - ～<ruby>間<rt>あい</rt></ruby>に", page:"genki/genki21-3/", beta:true},
      {title:"Lesson 21-4 - Adjective + する", page:"genki/genki21-4/", beta:true},
      {title:"Lesson 21-5 - ～てほしい", page:"", page:"genki/genki21-5/", beta:true},
      {title:"Vocab", page:"", page:"genki/vocab21/"},
      {title:"Sentence Review", page:"", patreon:"https://www.patreon.com/posts/39913794"},
    ],
    "Lesson 22": [
      {title:"Lesson 22-1 - Causative Sentences", page:"genki/genki22-1/", beta:true},
      {title:"Lesson 22-2 - Verb Stem + なさい", page:"genki/genki22-2/", beta:true},
      {title:"Lesson 22-3 - ～ば", page:"genki/genki22-3/", beta:true},
      {title:"Lesson 22-4 - ～のに", page:"genki/genki22-4/", beta:true},
      {title:"Lesson 22-5 - ～のような/～のように", page:"genki/genki22-5/", beta:true},
      {title:"Vocab", page:"genki/vocab22/"},
      {title:"Sentence Review", page:"", patreon:"https://www.patreon.com/posts/39913794"},
    ],
    "Lesson 23": [
      {title:"Lesson 23-1 - Causative-passive Sentences", page:"genki/genki23-1/", beta:true},
      {title:"Lesson 23-2 - ～ても", page:"genki/genki23-2/", beta:true},
      {title:"Lesson 23-3 - ～ことにする", page:"genki/genki23-3/", beta:true},
      {title:"Lesson 23-4 - ～まで", page:"genki/genki23-4/", beta:true},
      {title:"Lesson 23-5 - ～<ruby>方<rt>かた</rt></ruby>", page:"genki/genki23-5/", beta:true},
      {title:"Vocab", page:"genki/vocab23/"},
      {title:"Sentence Review", page:"", patreon:"https://www.patreon.com/posts/39913794"},
    ],
    "": [],
    "Review": [
      {title:"Genki II - Complete Sentence Review", page:"", patreon:"https://www.patreon.com/posts/47029146/"},
      {title:"Genki II - Complete Vocab Review",    page:"", patreon:"https://www.patreon.com/posts/79196752/"}
    ]
  },
  "Genki I + II": {
    "Review": [
      {title:"Genki I + II - Complete Vocab Review",    page:"", patreon:"https://www.patreon.com/posts/79196752/"}
    ]
  },
  "comingsoon":[""]
}

export default genki;
